import React from "react";
import { useHistory } from "react-router";
import "./home.scss";
import netlifyIdentity from "netlify-identity-widget";

const HomePage = () => {
  const history = useHistory();

  // const goToVip = () => {
  //   history.push("/vip");
  // };

  const goToCalculator = () => {
    history.push("/bills");
  };

  const user = netlifyIdentity.currentUser();
  console.log(user);

  return (
    <div className="Home">
      <div className="Home-header">
        <p>hello {user && user.user_metadata.full_name} </p>
        <button onClick={goToCalculator}>Bill Calculator</button>
        {/* <button onClick={goToVip}>VIP Area</button> */}
      </div>
    </div>
  );
};

export default HomePage;
