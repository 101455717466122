import React, { useEffect, useState } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import HomePage from "./pages/home";
import VipPage from "./pages/vip";
import netlifyIdentity, { User } from "netlify-identity-widget";
import BillsPage from "./pages/bills";

export const App = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (netlifyIdentity.currentUser()) {
      setCurrentUser(netlifyIdentity.currentUser());
      setIsAuthenticated(true);
    }
  }, []);

  const netlifyAuth = {
    authenticate() {
      console.log("hello");
      netlifyIdentity.open();
      netlifyIdentity.on("login", (u) => {
        console.log("welcome", u);
        setIsAuthenticated(true);
        setCurrentUser(netlifyIdentity.currentUser());
      });
    },
    signout() {
      netlifyIdentity.open();
      netlifyIdentity.on("logout", () => {
        setIsAuthenticated(false);
        setCurrentUser(null);
      });
    },
  };

  console.log(currentUser);

  const LoginButton = () => {
    const login = () => {
      netlifyAuth.authenticate();
    };

    const logout = () => {
      netlifyAuth.signout();
    };

    return (
      <div className="userButtonContainer">
        {isAuthenticated ? (
          <button className="userButton" onClick={logout}>
            Log out
          </button>
        ) : (
          <button className="userButton" onClick={login}>
            Log in
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="App">
      <Router>
        <div className="App-header">
          <Link to="/" className="logo">
            frod.co
          </Link>
          <LoginButton />
        </div>

        <div className="App-body">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/home" component={HomePage} />
            <Route path="/bills" component={BillsPage} />
            <Route path="/vip">
              {!isAuthenticated ? <Redirect to="/" /> : <VipPage />}
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
};
