import React, { ChangeEvent } from "react";
import "./gameInput.scss";

interface InputProps<T> {
  label: string;
  value: T;
  setValue: (value: T) => void;
}

const GameInput = <T extends string | number>({
  label,
  value,
  setValue,
}: InputProps<T>) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const a =
      typeof value === "number"
        ? (parseInt(event.target.value) as T)
        : (event.target.value as T);
    setValue(a);
  };

  return (
    <div className="inputField">
      <label className="label">{label}</label>
      <input
        className="input"
        type="text"
        value={value}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default GameInput;
