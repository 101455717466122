import { useState } from "react";
import "./bills.scss";

const BillsPage = () => {
  const [billTotal, setTotal] = useState<number>(0);
  const [numberOfPeople, setNumberOfPeople] = useState<number>(1);
  const [numberOfNewStarters, setNumberOfNewStarters] = useState<number>(0);
  const [numberOfIndividuals, setNumberOfIndividuals] = useState<number>(0);
  const [individualTotal, setIndividualTotal] = useState<number>(0);
  const [amountOnMorale, setAmountOnMorale] = useState<number>(10);
  const [amountPaid, setAmountPaid] = useState<number>(0);
  const [tipPercentage, setTipPercentage] = useState<number>(10);

  const tipMultiplier = roundToThreePlaces((tipPercentage / 100));
  const tip = roundToThreePlaces(billTotal * tipMultiplier);
  const totalWithTip = roundToThreePlaces(tip + billTotal);
  const individualTotalWithMorale = roundToThreePlaces(individualTotal + (amountOnMorale * numberOfIndividuals))

  const totalWithTipAfterIndivuals = roundToThreePlaces(totalWithTip - individualTotalWithMorale);

  const basePerPerson = roundUpToTwoPlaces(totalWithTipAfterIndivuals / (numberOfPeople - numberOfIndividuals));
  const afterPerPerson = roundToThreePlaces(basePerPerson - amountOnMorale);

  const numberOfSplitters = numberOfPeople - numberOfNewStarters - numberOfIndividuals;
  const initialMorale = roundToThreePlaces((numberOfPeople - numberOfNewStarters) * amountOnMorale);
  const newStarterOnMorale = roundToThreePlaces(numberOfNewStarters * basePerPerson);

  const totalForMorale = roundToThreePlaces(initialMorale + newStarterOnMorale);

  const exactToBePaid = roundUpToTwoPlaces((afterPerPerson * (numberOfSplitters)) + totalForMorale + individualTotal);

  const moraleRounded = Math.ceil(totalForMorale);
  const eachPersonRounded = Math.ceil(afterPerPerson);
  const exactAfterRounding = roundToThreePlaces(moraleRounded + (numberOfSplitters * eachPersonRounded) + individualTotalWithMorale);
  const equivalentTip = roundToThreePlaces((exactAfterRounding / billTotal - 1) * 100);

  return (
    <div className="Bills">
      <form>
        <div className="input-row">
          <label className="input-label">Total on bill</label>
          <input className="number-input" type="number" value={billTotal} onChange={(e) => setTotal(parseFloat(e.target.value))}/>
        </div>
        <div className="input-row">
          <label className="input-label">Total number of people</label>
          <input className="number-input" type="number" value={numberOfPeople} onChange={(e) => setNumberOfPeople(parseInt(e.target.value))}/>
        </div>
        <div className="input-row">
          <label className="input-label">Number of new starters</label>
          <input className="number-input" type="number" value={numberOfNewStarters} onChange={(e) => setNumberOfNewStarters(parseInt(e.target.value))}/>
        </div>
        <div className="input-row">
          <label className="input-label">Number of people paying solo</label>
          <input className="number-input" type="number" value={numberOfIndividuals} onChange={(e) => setNumberOfIndividuals(parseInt(e.target.value))}/>
        </div>
        <div className="input-row">
          <label className="input-label">Individual total</label>
          <input className="number-input" type="number" value={individualTotal} onChange={(e) => setIndividualTotal(parseFloat(e.target.value))}/>
        </div>
        <div className="input-row">
          <label className="input-label">Amount on morale per person</label>
          <input className="number-input" type="number" value={amountOnMorale} onChange={(e) => setAmountOnMorale(parseFloat(e.target.value))}/>
        </div>
        <div className="input-row">
          <label className="input-label">Amount already paid (deposit)</label>
          <input className="number-input" type="number" value={amountPaid} onChange={(e) => setAmountPaid(parseFloat(e.target.value))}/>
        </div>
        <div className="input-row">
          <label className="input-label">Tip percentage</label>
          <input className="number-input" type="number" value={tipPercentage} onChange={(e) => setTipPercentage(parseInt(e.target.value))}/>
        </div>
    
      </form>

      <div className="">
        <span>Exact total (with tip): {billTotal} + {tip} = {totalWithTip}</span>
      </div>
      <div className="">
        <span>Individuals' total: ({numberOfIndividuals} x {amountOnMorale}) + {individualTotal} = {individualTotalWithMorale}</span>
      </div>
      <div className="">
        <span>Exact total (with tip) after individuals: {totalWithTip} - {individualTotalWithMorale} = {totalWithTipAfterIndivuals}</span>
      </div>
      <div className="">
        <span>Splitting between: {numberOfPeople} - {numberOfIndividuals} = {numberOfSplitters + numberOfNewStarters} people</span>
      </div>
      <div className="">
        <span>Each splitting person costs: {totalWithTipAfterIndivuals} / {numberOfSplitters + numberOfNewStarters} = {basePerPerson}</span>
      </div>
      <div className="">
        <span>Morale pays: ({numberOfSplitters + numberOfIndividuals} x {amountOnMorale}) + ({numberOfNewStarters} x {basePerPerson}) = {totalForMorale}</span>
      </div>
      <div className="">
        <span>Each splitting non starter pays ({numberOfSplitters}): {afterPerPerson}</span>
      </div>
      <div className="">
        <span>Exact to be paid: {exactToBePaid}</span>
      </div>
      ----------
      <div className="">
        <span>Morale rounded up: {moraleRounded}</span>
      </div>
      <div className="">
        <span>Each person rounded up: {eachPersonRounded}</span>
      </div>
      <div className="">
        <span>Exact to be paid after rounding: {moraleRounded} + ({numberOfSplitters} x {eachPersonRounded}) + {individualTotalWithMorale} = {exactAfterRounding}</span>
      </div>
      <div className="">
        <span>Equivalent tip: {equivalentTip}%</span>
      </div>
    </div>
  );
};

const roundUpToTwoPlaces = (num: number): number => {
  const a = num * 100;
  const b = Math.ceil(a)
  const c = b / 100

  return c;
}

const roundToThreePlaces = (num: number): number => {
  const a = num * 1000;
  const b = Math.round(a)
  const c = b / 1000

  return c;
}

export default BillsPage;
