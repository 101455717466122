import { Expr } from "faunadb";
import React, { useEffect, useState } from "react";
import GameInput from "../components/gameInput";
import "./vip.scss";

type FaunaPage = {
  after?: FaunaGame;
  before?: FaunaGame;
  data: Game[];
};

type FaunaGame = {
  ref: Expr;
  ts: string;
  data: { game: string };
};

type Game = {
  id: string;
  game: string;
};

const VipPage = () => {
  const [currentGame, setCurrentGame] = useState("");
  const [games, setGames] = useState<Game[]>();
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    getGames(pageSize);
  }, [pageSize]);

  const postGame = async () => {
    if (currentGame !== "") {
      const body = { game: currentGame };
      const response = await fetch("/.netlify/functions/post-game", {
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();
      console.log(data);
      console.log(`successfully added ${currentGame}`);
      setCurrentGame("");
    } else {
      console.log("don't enter empty string >:(");
    }
  };

  const getGames = async (pageSize: number) => {
    console.log("getting...");
    const queryParam = `/?pageSize=${pageSize}`;
    const response = await fetch(`/.netlify/functions/get-games${queryParam}`, {
      method: "GET",
    });
    const page = (await response.json()) as FaunaPage;
    setGames(page.data);
  };

  const deleteGame = async (game: Game) => {
    console.log("deleting...");
    const queryParam = `/?id=${game.id}`;
    const response = await fetch(
      `/.netlify/functions/delete-game/${queryParam}`,
      {
        method: "POST",
      }
    );
    const data = await response.json();
    console.log(data);
    console.log(`successfully deleted ${game.game}`);
    getGames(pageSize);
  };

  console.log(games);

  return (
    <div className="Vip">
      <div className="Home-header">
        <p>hello VIP</p>
        <div className="inputRow">
          <GameInput
            label="enter a game:"
            value={currentGame}
            setValue={setCurrentGame}
          />
          <button className="submitButton" onClick={postGame}>
            submit
          </button>
        </div>
        <div className="inputRow">
          <GameInput
            label="enter page size:"
            value={pageSize}
            setValue={setPageSize}
          />
          <button className="submitButton" onClick={postGame}>
            submit
          </button>
        </div>
        {games &&
          games.map((game) => (
            <div className="gameRow" key={game.id}>
              <div className="game">{game.game}</div>
              <button onClick={() => deleteGame(game)}>delete</button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VipPage;
